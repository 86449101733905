<template>
    <tippy-mobile name="share">
        <div class="info share-tippy-content">
            <div class="subtitle text-center text-white pb-3">
                <Translate translationKey="quests-dashboard.shareItNowOn"
                           fallback="Share it NOW on:" />
            </div>
            <div class="share-now d-flex justify-content-center align-items-center">
                <div class="social-icons d-flex justify-content-center align-items-center">
                    <span class="i-background mr-2"
                          @click="shareTippyStore.share(shareTippyStore.twitterShareLink)">
                        <svg viewBox="0 0 24 24"
                             aria-hidden="true">
                            <g>
                                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161
                                              17.52h1.833L7.084 4.126H5.117z" />
                            </g>
                        </svg>
                    </span>
                    <span class="i-background mr-2"
                          @click="shareTippyStore.share(shareTippyStore.facebookShareLink)">
                        <i class="fa-brands fa-facebook-f" />
                    </span>
                    <span class="i-background mr-2"
                          @click="shareTippyStore.share(shareTippyStore.linkedInShareLink)">
                        <i class="fa-brands fa-linkedin-in" />
                    </span>
                    <span class="i-background mr-2"
                          @click="shareTippyStore.share(shareTippyStore.telegramShareLink)">
                        <i class="fa-kit fa-telegram" />
                    </span>
                    <span class="i-background"
                          @click="shareTippyStore.copyShareText">
                        <i class="fa-solid fa-copy" />
                    </span>
                </div>
            </div>
        </div>
    </tippy-mobile>
</template>

<script setup lang="ts">
    import TippyMobile from './TippyMobile.vue';
    import { useShareTippyStore } from '@/js/store/quests/dashboard/ShareTippyStore';

    const shareTippyStore = useShareTippyStore();
</script>
