<template>
    <div class="closing-curtains">
        <div class="curtain-close left" />
        <div class="curtain-close right" />
        <slot />
    </div>
</template>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";
@import "@/sass/missions-partials/z-index";

.closing-curtains {
    .curtain-close {
        position: fixed;
        z-index: $closing-curtains-z-index;
        top: 0;
        width: 51%;
        height: 100vh;
        background-color: $brand950 !important;
        transition: transform 1s;
    }

    .left {
        left: 0;
        transform: translateX(-100%);
        transform-origin: left center;
    }

    .right {
        right: 0;
        transform: translateX(100%);
        transform-origin: right center;
    }
}
</style>
